import { Tag, Tooltip } from 'antd'
import axios from 'axios'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useAxios from '../../../../../app/api/apiHook'
import { FeedbackMessageType } from '../../../../../app/types'
import AntFormItemExplain from '../../../../../components/AntFormItemExplain/AntFormItemExplain'
import { assetsSlice, Icon, selectFamily } from '../../../assetsSlice'
import styles from './TagsSection.module.scss'

interface AutoTagsSectionProps {
  icon: Icon
  autoTagsSynonyms: string[]
}

const AutoTagsSynonymsSection: FunctionComponent<AutoTagsSectionProps> = ({
  icon,
  autoTagsSynonyms: initialAutoTagsSynonyms,
}) => {
  const [autoTagsSynonyms, setAutoTagsSynonyms] = useState(initialAutoTagsSynonyms || [])
  const family = useSelector(selectFamily)
  const [feedbackStatus, setFeedbackStatus] = useState<{
    type: FeedbackMessageType
    message: string
  }>({
    type: 'text',
    message: '',
  })

  const dispatch = useDispatch()

  const [, patchAutoTags] = useAxios(
    {
      url: `/v4/icons/${icon.hash}/auto-tags-synonyms`,
      method: 'PATCH',
    },
    { manual: true }
  )

  const removeAutoTag = async (removedAutoTag: string) => {
    try {
      setFeedbackStatus({ type: 'text', message: 'Removing...' })

      const remainingAutoTags = autoTagsSynonyms.filter((autoTag) => autoTag !== removedAutoTag)

      await patchAutoTags({ data: { tags: remainingAutoTags } })

      setAutoTagsSynonyms(remainingAutoTags)
      dispatch(
        assetsSlice.actions.updateIconOnList({ ...icon, autoTagsSynonyms: remainingAutoTags })
      )

      setFeedbackStatus({ type: 'success', message: 'Auto tag synonym removed!' })
    } catch (error: any) {
      if (!axios.isCancel(error)) {
        console.error(error.message)
        setFeedbackStatus({ type: 'error', message: 'Something went wrong, please try again' })
      }
    }
  }

  useEffect(() => {
    setAutoTagsSynonyms(icon.autoTagsSynonyms || [])
  }, [icon])

  if (!family) {
    return null
  }

  return (
    <div className={styles.rootAutoTags}>
      {autoTagsSynonyms.length > 0 && (
        <span>
          <b>Auto tags from synonyms: </b>
        </span>
      )}
      {autoTagsSynonyms &&
        autoTagsSynonyms.map((autoTag) => (
          <Tooltip title={autoTag.length > 20 ? autoTag : undefined} key={autoTag}>
            <Tag key={`${icon.hash}_${autoTag}`} closable onClose={() => removeAutoTag(autoTag)}>
              <span className={styles.longTags}>{autoTag}</span>
            </Tag>
          </Tooltip>
        ))}

      <AntFormItemExplain message={feedbackStatus.message} type={feedbackStatus.type} />
    </div>
  )
}

export default AutoTagsSynonymsSection
