import { Button, Col, Collapse, message, Row } from 'antd'
import Layout from 'antd/lib/layout/layout'
import React, { FunctionComponent, useEffect, useState } from 'react'

import Title from 'antd/lib/typography/Title'
import { format } from 'date-fns'
import useAxios from '../../app/api/apiHook'
import { ProductTypeEnum } from '../assets/assetsSlice'
import TagItem from './TagItem/TagItem'
import styles from './Tags.module.scss'
import { TagsPagesStatus } from './types'
import handleError from '../../app/api/handleError'
import TagsUploadSpreadsheet from './TagsUploadSpreadsheet'

const Tags: FunctionComponent = () => {
  const [isUploadDrawerVisible, setIsUploadDrawerVisible] = useState(false)

  const [{ loading: loadingToggleTagPageStatus }, toggleTagPageStatus] = useAxios(
    {
      url: '/v4/preferences/tags-pages',
      method: 'PATCH',
    },
    { manual: true }
  )

  const [{ data: initialTagsData, loading, error: loadingTagsError }, refetchTags] = useAxios(
    '/v4/preferences/tags-pages'
  )

  const [tagsData, setTagsData] = useState<TagsPagesStatus>({
    allowed: {
      icons: [],
      illustrations: [],
      emojis: [],
      elements: [],
    },
    disallowed: {
      icons: [],
      illustrations: [],
      emojis: [],
      elements: [],
    },
  })

  const [{ loading: loadingRefresh }, refreshTagsPages] = useAxios(
    {
      url: '/v4/preferences/tags-pages-refresh',
      method: 'PATCH',
    },
    { manual: true }
  )

  const [{ loading: loadingDownload }, downloadSpreadsheet] = useAxios(
    {
      url: '/v4/preferences/tags-pages/download',
      method: 'GET',
      responseType: 'blob',
    },
    { manual: true }
  )

  const handleDownloadSpreadsheet = async () => {
    try {
      const buffer = await downloadSpreadsheet()

      const blob = new Blob([buffer.data])

      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.href = url
      a.download = `tags-pages-${format(new Date(), 'yyyy-MM-dd-HH-mm')}.xlsx`
      a.click()
      window.URL.revokeObjectURL(url)
    } catch (error) {
      message.error(`Something went wrong when downloading the file: ${handleError(error)}`)
    }
  }

  // We do this below just to update the UI without making an extra request to our backend
  const updateListOfTagsInTheUI = (
    productType: ProductTypeEnum,
    tag: string,
    status: 'allow' | 'disallow'
  ) => {
    const tagsDataCopy = { ...tagsData }

    if (status === 'allow') {
      // removes from disallowed list and adds to allowed
      tagsDataCopy.disallowed[productType] = tagsDataCopy.disallowed[productType].filter(
        (t: string) => t !== tag
      )
      tagsDataCopy.allowed[productType].push(tag)
    } else {
      // removes from allowed list and adds to disallowed
      tagsDataCopy.allowed[productType] = tagsDataCopy.allowed[productType].filter(
        (t: string) => t !== tag
      )
      tagsDataCopy.disallowed[productType].push(tag)
    }
    setTagsData(tagsDataCopy)
  }

  const handleTagClick = async (
    productType: ProductTypeEnum,
    tag: string,
    status: 'allow' | 'disallow'
  ) => {
    try {
      updateListOfTagsInTheUI(productType, tag, status)
      await toggleTagPageStatus({ data: { productType, tag, status } })

      message.success(`${tag} tag ${status}ed with success`)
    } catch (error) {
      console.error(error)
      message.error(`Something went wrong: ${error}`)
    }
  }

  // Here we set the tags data with the data coming from our server
  useEffect(() => {
    if (initialTagsData) {
      setTagsData({ ...initialTagsData })
    }
  }, [initialTagsData])

  if (loading) return <p>Loading tags...</p>
  if (loadingTagsError)
    return (
      <p>
        Some error happened when loading the page, please refresh the page. If the problem persists,
        please contact one of our developers on Slack.
      </p>
    )

  return (
    <>
      <Layout>
        <div className={styles.root}>
          <Row justify="center" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} lg={20}>
              <Title>Tags Pages Manager</Title>

              <Button
                onClick={async () => {
                  await refreshTagsPages()
                  refetchTags()
                }}
                loading={loadingRefresh}
              >
                Refresh list
              </Button>

              <Button
                onClick={() => handleDownloadSpreadsheet()}
                loading={loadingDownload}
                className={styles.exportButton}
              >
                Export spreadsheet
              </Button>

              <Button
                onClick={() => setIsUploadDrawerVisible(true)}
                loading={loadingDownload}
                className={styles.exportButton}
              >
                Import spreadsheet
              </Button>

              <Collapse>
                {Object.values(ProductTypeEnum).map((type) => (
                  <Collapse.Panel key={type} header={`${type} TAGS`}>
                    <p>Allowed:</p>
                    {tagsData.allowed[type].map((tag: string) => (
                      <TagItem
                        key={`allowed-${type}-${tag}`}
                        tag={tag}
                        status="disallow"
                        productType={type}
                        onClick={handleTagClick}
                        disabled={loadingToggleTagPageStatus}
                      />
                    ))}

                    <p>Disallowed:</p>
                    {tagsData.disallowed[type].map((tag: string) => (
                      <TagItem
                        key={`disallowed-${type}-${tag}`}
                        tag={tag}
                        status="allow"
                        productType={type}
                        onClick={handleTagClick}
                        disabled={loadingToggleTagPageStatus}
                      />
                    ))}
                  </Collapse.Panel>
                ))}
              </Collapse>
            </Col>
          </Row>
        </div>

        <TagsUploadSpreadsheet
          onClose={() => setIsUploadDrawerVisible(false)}
          onSuccess={(newTags) => {
            setIsUploadDrawerVisible(false)
            setTagsData(newTags)
          }}
          visible={isUploadDrawerVisible}
        />
      </Layout>
    </>
  )
}

export default Tags
