import Layout from 'antd/lib/layout/layout'
import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import AssetsFooter from './assets-footer/AssetsFooter'
import AssetsHeader from './assets-header/AssetsHeader'
import styles from './Assets.module.scss'
import { selectDrawer } from './assetsSlice'
import CategoriesPanel from './categories-panel/CategoriesPanel'
import CategoryDrawer from './category-drawer/CategoryDrawer'
import ColorScanDrawer from './color-scan-drawer/ColorScanDrawer'
import FamiliesHelp from './families-help/FamiliesHelp'
import FamilyPackUploadDrawer from './family-drawer/family-pack-upload-drawer/FamilyPackUploadDrawer'
import FamilyDrawer from './family-drawer/FamilyDrawer'
import IconDrawer from './icon-drawer/IconDrawer'
import IconsPanel from './icons-panel/IconsPanel'
import MultipleIconsDrawer from './multiple-icons-drawer/MultipleIconsDrawer'
import SubcategoriesPanel from './subcategories-panel/SubcategoriesPanel'
import SubcategoryDrawer from './subcategory-drawer/SubcategoryDrawer'
import FixRestoreDrawer from '../image-engine/fix-restore-drawer/FixRestoreDrawer'
import FixFamilyDrawer from '../image-engine/fix-family-drawer/FixFamilyDrawer'
import RestoreFamilyDrawer from '../image-engine/restore-family-drawer/RestoreFamilyDrawer'
import FamilyGroupDrawer from './family-group-drawer/FamilyGroupDrawer'
import FixAllFamiliesDrawer from '../image-engine/fix-family-drawer/FixAllFamiliesDrawer'
import CategoryUploadDrawer from './category-drawer/category-upload-drawer/CategoryUploadDrawer'

const drawerComponentByType = {
  'color-scan': ColorScanDrawer,
  icon: IconDrawer,
  'multiple-icons': MultipleIconsDrawer,
  category: CategoryDrawer,
  subcategory: SubcategoryDrawer,
  family: FamilyDrawer,
  'family-upload': FamilyPackUploadDrawer,
  'fix-restore': FixRestoreDrawer,
  'fix-family': FixFamilyDrawer,
  'fix-all-families': FixAllFamiliesDrawer,
  'restore-family': RestoreFamilyDrawer,
  'family-group': FamilyGroupDrawer,
  'upload-category-assets': CategoryUploadDrawer,
}

const Assets: FunctionComponent = () => {
  const drawer = useSelector(selectDrawer)
  const DrawerComponent: FunctionComponent | null = drawer?.visible
    ? drawerComponentByType[drawer.type]
    : null

  return (
    <>
      <AssetsHeader />
      <div className={styles.root}>
        <Layout>
          <FamiliesHelp />
          <CategoriesPanel />
          <SubcategoriesPanel />
          <IconsPanel />
          {DrawerComponent && <DrawerComponent />}
        </Layout>
      </div>
      <AssetsFooter />
    </>
  )
}

export default Assets
