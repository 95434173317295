import { useLocation } from 'react-router'
import {
  EditOutlined,
  FileDoneOutlined,
  FormatPainterOutlined,
  PlusOutlined,
  RedoOutlined,
  UploadOutlined,
  TagOutlined,
  CloudUploadOutlined,
  CloudDownloadOutlined,
} from '@ant-design/icons'
import { Button, Checkbox, Select, Slider, Switch, Tooltip } from 'antd'
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as DescriptionIcon } from '../../../assets/Description--Streamline-Core.svg'
import { ReactComponent as ReplaceIcon } from '../../../assets/replace.svg'

import * as assetsSlice from '../assetsSlice'
import { ProductTypeEnum } from '../assetsSlice'
import styles from './AssetsHeader.module.scss'
import GenerateDescriptionsModal from '../family-drawer/GenerateDescriptionsModal'
import ExportFamilyAssetsModal from '../family-drawer/ExportFamilyAssetsModal'

const { Option, OptGroup } = Select

const AssetsHeader: FunctionComponent = () => {
  const families = useSelector(assetsSlice.selectFamiliesByOrder)
  const family = useSelector(assetsSlice.selectFamily)
  const loadingFamilies = useSelector(assetsSlice.selectLoadingFamilies)
  const categories = useSelector(assetsSlice.selectCategoriesByOrder)
  const loadingCategories = useSelector(assetsSlice.selectLoadingCategories)
  const showTags = useSelector(assetsSlice.selectShowTags)
  const previewSize = useSelector(assetsSlice.selectPreviewSize)
  const subcategory = useSelector(assetsSlice.selectSubcategory)
  const selectAllCheckbox = useSelector(assetsSlice.selectAllCheckbox)
  const autoTaggingAssets = useSelector(assetsSlice.selectAutoTaggingAssets)
  const isUpdatingAssetsOnSendOwl = useSelector(assetsSlice.selectIsUpdatingAssetsOnSendOwl)
  const [isGenerateDescriptionsModalVisible, setIsGenerateDescriptionsModalVisible] =
    useState(false)
  const [isExportFamilyAssetsModalVisible, setIsExportFamilyAssetsModalVisible] = useState(false)

  const location = useLocation()
  const openFixFamiliesTool =
    new URLSearchParams(location.search).get('openFixFamiliesTool') === 'true'

  const dispatch = useDispatch()

  const onChangeShowTags = (checked: boolean) => {
    dispatch(assetsSlice.actions.setShowTags(checked))
  }

  const onChangePreviewSize = (size: number) => {
    dispatch(assetsSlice.actions.setPreviewSize(size))
  }

  const openEditFamilyDrawer = () => {
    dispatch(assetsSlice.actions.toggleDrawer({ visible: true, type: 'family', data: family }))
  }

  const openColorScanDrawer = () => {
    dispatch(assetsSlice.actions.toggleDrawer({ visible: true, type: 'color-scan', data: family }))
  }

  const openFixFamilyDrawer = () => {
    dispatch(assetsSlice.actions.toggleDrawer({ visible: true, type: 'fix-family', data: family }))
  }

  const openFixAllFamiliesDrawer = useCallback(() => {
    dispatch(
      assetsSlice.actions.toggleDrawer({ visible: true, type: 'fix-all-families', data: family })
    )
  }, [dispatch, family])

  const openRestoreFamilyDrawer = () => {
    dispatch(
      assetsSlice.actions.toggleDrawer({ visible: true, type: 'restore-family', data: family })
    )
  }

  const openUploadFamilyDrawer = () => {
    dispatch(
      assetsSlice.actions.toggleDrawer({ visible: true, type: 'family-upload', data: family })
    )
  }

  const openCreateFamilyDrawer = () => {
    dispatch(assetsSlice.actions.toggleDrawer({ visible: true, type: 'family', data: null }))
  }

  const openCreateFamilyGroupDrawer = () => {
    dispatch(assetsSlice.actions.toggleDrawer({ visible: true, type: 'family-group', data: null }))
  }

  const generateTagsForFamily = () => {
    if (family) {
      dispatch(assetsSlice.generateTagsForFamily(family.hash, family?.productType))
    }
  }

  const generateDescriptionsForFamily = () => {
    setIsGenerateDescriptionsModalVisible(true)
  }

  const exportFamilyAssets = () => {
    setIsExportFamilyAssetsModalVisible(true)
  }

  const updateAssetsOnSendOwl = () => {
    if (family) {
      dispatch(assetsSlice.updateAssetsOnSendOwl(family.hash))
    }
  }

  const handleChangeFamily = (value: string) => {
    dispatch(assetsSlice.actions.selectedFamily(value))
    dispatch(assetsSlice.getCategories(value))
  }

  useEffect(() => {
    dispatch(assetsSlice.getFamilies())
  }, [dispatch])

  useEffect(() => {
    if (openFixFamiliesTool) {
      openFixAllFamiliesDrawer()
    }
  }, [openFixAllFamiliesDrawer, openFixFamiliesTool])

  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <div className={styles.left}>
          <Select<string, { value: string; children: string }>
            value={family?.hash}
            className={styles.select}
            onChange={handleChangeFamily}
            loading={loadingFamilies}
            placeholder="Select family"
            listHeight={700}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
              if (option?.children) {
                return option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              return false
            }}
          >
            {families &&
              Object.values(ProductTypeEnum).map((productType) => (
                <OptGroup key={productType} label={productType}>
                  {families
                    .filter((iteratedFamily) => iteratedFamily.productType === productType)
                    .map((iteratedFamily) => (
                      <Option key={iteratedFamily.hash} value={iteratedFamily.hash}>
                        {iteratedFamily.name}
                      </Option>
                    ))}
                </OptGroup>
              ))}
          </Select>
          {family && (
            <>
              <Tooltip title="Edit family" placement="bottom">
                <Button
                  type="text"
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => openEditFamilyDrawer()}
                />
              </Tooltip>
              <Tooltip title="Update family assets on SendOwl" placement="bottom">
                <Button
                  type="text"
                  shape="circle"
                  icon={<CloudUploadOutlined />}
                  onClick={() => updateAssetsOnSendOwl()}
                  loading={isUpdatingAssetsOnSendOwl}
                />
              </Tooltip>
              <Tooltip
                title={
                  categories?.length > 0
                    ? 'You can upload family pack just for empty families'
                    : 'Upload family pack'
                }
                placement="bottom"
              >
                <Button
                  disabled={categories?.length > 0 || loadingCategories}
                  type="text"
                  shape="circle"
                  icon={<UploadOutlined />}
                  onClick={() => openUploadFamilyDrawer()}
                />
              </Tooltip>
              <Tooltip title="Scan colors tool" placement="bottom">
                <Button
                  type="text"
                  shape="circle"
                  icon={<FormatPainterOutlined />}
                  onClick={() => openColorScanDrawer()}
                />
              </Tooltip>
              <Tooltip title="Fix SVGs" placement="bottom">
                <Button
                  type="text"
                  shape="circle"
                  icon={<FileDoneOutlined />}
                  onClick={() => openFixFamilyDrawer()}
                />
              </Tooltip>
              <Tooltip title="Restore SVGs" placement="bottom">
                <Button
                  type="text"
                  shape="circle"
                  icon={<RedoOutlined />}
                  onClick={() => openRestoreFamilyDrawer()}
                />
              </Tooltip>
              <Tooltip title="Auto generate tags using AI" placement="bottom">
                <Button
                  type="text"
                  shape="circle"
                  icon={<TagOutlined />}
                  onClick={() => generateTagsForFamily()}
                  loading={autoTaggingAssets}
                />
              </Tooltip>
              <Tooltip title="Generate descriptions using AI" placement="bottom">
                <Button
                  type="text"
                  shape="circle"
                  icon={<DescriptionIcon />}
                  onClick={() => generateDescriptionsForFamily()}
                  loading={autoTaggingAssets}
                />
              </Tooltip>
              <Tooltip title="Export family assets" placement="bottom">
                <Button
                  type="text"
                  shape="circle"
                  icon={<CloudDownloadOutlined />}
                  onClick={() => exportFamilyAssets()}
                />
              </Tooltip>
            </>
          )}
        </div>

        <div className={styles.right}>
          {subcategory?.hash && (
            <div className={styles.selectAll}>
              <Checkbox
                checked={selectAllCheckbox}
                className={styles.checkbox}
                onChange={(e) =>
                  dispatch(assetsSlice.actions.toggleSelectedAllIcons(e.target.checked))
                }
              />
              Select All
            </div>
          )}
          {subcategory && (
            <>
              <span className={styles.previewSizeText}>Preview Size</span>
              <div className={styles.sliderArea}>
                <Slider
                  className={styles.slider}
                  defaultValue={previewSize}
                  min={16}
                  max={256}
                  onChange={onChangePreviewSize}
                />
              </div>
              <div>
                <Switch checked={showTags} onChange={onChangeShowTags} />
                <span className={styles.showTagsText}>Show Tags</span>
              </div>
            </>
          )}
          <Tooltip title="Fix all families SVGs" placement="bottom">
            <Button
              size="large"
              type="text"
              shape="circle"
              icon={<FileDoneOutlined />}
              onClick={() => openFixAllFamiliesDrawer()}
            />
          </Tooltip>

          <Button icon={<PlusOutlined />} onClick={() => openCreateFamilyDrawer()} type="primary">
            New Family
          </Button>
          <Button
            onClick={() => openCreateFamilyGroupDrawer()}
            type="primary"
            className={styles.familyGroupsButton}
          >
            Manage Family Groups
          </Button>
        </div>
      </div>

      {isGenerateDescriptionsModalVisible && family && (
        <GenerateDescriptionsModal
          onClose={() => setIsGenerateDescriptionsModalVisible(false)}
          family={family}
        />
      )}

      {isExportFamilyAssetsModalVisible && family && (
        <ExportFamilyAssetsModal
          onClose={() => setIsExportFamilyAssetsModalVisible(false)}
          family={family}
        />
      )}
    </div>
  )
}

export default AssetsHeader
