import React from 'react'
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import * as assetsSlice from '../assetsSlice'
import IconEmptyState from './icon-empty-state/IconEmptyState'
import AddTagsMultipleIcons from './icon-item/tags-section/AddTagsMultipleIcons'
import styles from './IconsPanel.module.scss'
import IconWrapper from './icon-item/IconWrapper'

export default function IconsPanel() {
  const dispatch = useDispatch()
  const icons = useSelector(assetsSlice.selectIcons)
  const loading = useSelector(assetsSlice.selectLoadingIcons)
  const subcategory = useSelector(assetsSlice.selectSubcategory)

  if (!loading && !subcategory) {
    return null
  }

  const Content = () => {
    if (loading) return <LoadingOutlined />

    if (!icons || icons.length === 0) return <IconEmptyState />

    return (
      <>
        {icons.map((i) => (
          <IconWrapper key={i.hash} icon={i} />
        ))}
      </>
    )
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        {subcategory && (
          <Tooltip title="Click here to refresh the list and check the updated tags suggestions or just to check the list in case some other person updated it. This saves nothing in the database, so it's safe to use whenever you want.">
            <Button
              icon={<ReloadOutlined />}
              type="link"
              onClick={() => dispatch(assetsSlice.getIcons(subcategory.hash))}
            >
              Refresh the list
            </Button>
          </Tooltip>
        )}
        <AddTagsMultipleIcons />
      </div>
      <div className={styles.content}>
        <Content />
      </div>
    </div>
  )
}
