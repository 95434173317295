import {
  PlusOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons'
import { Input, Tag, Tooltip } from 'antd'
import axios from 'axios'
import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useAxios from '../../../../../app/api/apiHook'
import { FeedbackMessageType } from '../../../../../app/types'
import AntFormItemExplain from '../../../../../components/AntFormItemExplain/AntFormItemExplain'
import { assetsSlice, Icon, selectFamily } from '../../../assetsSlice'
import styles from './TagsSection.module.scss'
import { moveItemLeft, moveItemRight } from '../../../../../utils/arrays.util'

interface TagsSectionProps {
  icon: Icon
  tags: string[]
}

const TagsSection: FunctionComponent<TagsSectionProps> = ({ icon, tags: initialTags }) => {
  const [inputVisible, setInputVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [tags, setTags] = useState(initialTags || [])
  const family = useSelector(selectFamily)
  const [feedbackStatus, setFeedbackStatus] = useState<{
    type: FeedbackMessageType
    message: string
  }>({
    type: 'text',
    message: '',
  })

  const saveInputRef = useRef<Input>(null)

  const dispatch = useDispatch()

  const [, saveTags] = useAxios(
    {
      url: `/v4/icons/${icon.hash}/tags`,
      method: 'PATCH',
    },
    { manual: true }
  )

  const [, patchAutoTags] = useAxios(
    {
      url: `/v4/icons/${icon.hash}/auto-tags`,
      method: 'PATCH',
    },
    { manual: true }
  )

  const handleClose = async (removedTag: string) => {
    try {
      setFeedbackStatus({ type: 'text', message: 'Saving...' })
      const tagsFiltered = tags.filter((tag) => tag !== removedTag)
      const response = await saveTags({
        data: {
          tags: tagsFiltered,
        },
      })

      const { tags: remainingTags } = response.data
      dispatch(assetsSlice.actions.updateIconOnList({ ...icon, tags: remainingTags }))

      setFeedbackStatus({ type: 'success', message: 'Tags updated!' })
    } catch (error: any) {
      if (!axios.isCancel(error)) {
        console.error(error.message)
        setFeedbackStatus({ type: 'error', message: 'Something went wrong, please try again' })
      }
    }
  }

  const showInput = () => {
    setInputVisible(true)
  }

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value)
  }

  const handleInputConfirmOnBlur = async () => {
    setInputVisible(false)
    handleInputConfirm()
  }

  const handleInputConfirm = async () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      await handleSaveTag(inputValue)
      setInputValue('')
    } else if (inputValue) {
      setFeedbackStatus({ type: 'warning', message: 'This tag already exists' })
    }
  }

  const handleSaveTag = async (newTag: string) => {
    if (newTag.trim() === '') {
      setFeedbackStatus({ type: 'error', message: 'Empty tags are not allowed!' })
      return
    }

    try {
      /* We don't save any tags with dash, it's always replaced with a space
        It's made this way so we can use just single dash in URLs.
        If you need more details please check Height task: https://height.app/hD7L-qOva/T-3159
      */
      const newTagNormalized = newTag.replace(/-/g, ' ')
      setFeedbackStatus({ type: 'text', message: 'Saving...' })
      const newTags = [...tags, newTagNormalized]
      setTags(newTags)

      // Remove possible duplicates
      const autoTags = icon.autoTags?.filter((tag) => !newTags.includes(tag))

      await saveTags({
        data: {
          tags: newTags,
        },
      })

      await patchAutoTags({ data: { tags: autoTags } })

      dispatch(
        assetsSlice.actions.updateIconOnList({
          ...icon,
          tags: newTags,
          autoTags,
        })
      )

      setFeedbackStatus({ type: 'success', message: 'Tags updated!' })
    } catch (e: any) {
      if (axios.isCancel(e)) {
        console.error('Previous request canceled, new request is send', e.message)
      } else {
        console.error(e.message)
        setFeedbackStatus({ type: 'error', message: 'Something went wrong, please try again' })
      }
    }
  }

  const moveTag = async (tag: string, direction: 'left' | 'right') => {
    let updatedTags = []

    if (direction === 'left') {
      updatedTags = moveItemLeft(tags, tag)
    } else {
      updatedTags = moveItemRight(tags, tag)
    }

    setTags(updatedTags)
    await saveTags({
      data: {
        tags: updatedTags,
      },
    })
  }

  useEffect(() => {
    if (inputVisible) {
      if (saveInputRef.current) {
        saveInputRef.current.input.focus()
      }
    }
  }, [inputVisible])

  useEffect(() => {
    setTags(initialTags)
  }, [initialTags])

  if (!family) {
    return null
  }

  return (
    <div className={styles.root}>
      <span>
        <b>Manual tags: </b>
      </span>
      {tags &&
        tags.map((tag) => (
          <Tooltip title={tag.length > 20 ? tag : undefined} key={tag}>
            <Tag
              key={`${icon.hash}_${tag}`}
              closable
              onClose={() => handleClose(tag)}
              closeIcon={<CloseCircleOutlined className={styles.removedTag} />}
            >
              <Tooltip title="Move to the left to make the tag more relevant">
                <ArrowLeftOutlined
                  className={styles.moveTagLeft}
                  onClick={() => moveTag(tag, 'left')}
                />
              </Tooltip>
              <span className={styles.longTags}>{tag}</span>
              <Tooltip title="Move to the right to make the tag less relevant">
                <ArrowRightOutlined
                  className={styles.moveTagRight}
                  onClick={() => moveTag(tag, 'right')}
                />
              </Tooltip>
            </Tag>
          </Tooltip>
        ))}
      {inputVisible ? (
        <Input
          ref={saveInputRef}
          type="text"
          size="small"
          className={styles.tagInput}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirmOnBlur}
          onPressEnter={handleInputConfirm}
        />
      ) : (
        <Tag className={styles.tagPlus} onClick={showInput}>
          <PlusOutlined /> New Tag
        </Tag>
      )}

      <AntFormItemExplain message={feedbackStatus.message} type={feedbackStatus.type} />
    </div>
  )
}

export default TagsSection
