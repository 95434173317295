import { format } from 'date-fns'
import { Button, Drawer, List, message, Spin, Tooltip, Typography, Collapse, Row } from 'antd'
import axios from 'axios'
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useAxios from '../../../app/api/apiHook'
import handleError from '../../../app/api/handleError'
import DrawerHeader from '../../../components/DrawerHeader/DrawerHeader'
import * as assetsSlice from '../../assets/assetsSlice'
import ChangesListFix from '../changes-list-fix/ChangesListFix'
import SVGComparison from '../fix-restore-drawer/SVGComparison'
import styles from './FixFamilyDrawer.module.scss'
import FixingFamiliesStatusEnum from './types'

const { Text } = Typography

export enum FixResultStatusEnum {
  ERROR = 'ERROR',
  NOT_MODIFIED = 'NOT_MODIFIED',
  SUCCESS = 'SUCCESS',
}

export type FixedIconResponse = {
  previousSVG: string
  fixedSVG: string
  status: FixResultStatusEnum
  path: string
  message?: string
}

export type FixedFamiliesResponse = {
  familySlug: string
  responseErrors: FixedIconResponse[]
  allIconsFixed: boolean
  errorPercentage: number
  fixingTimeout: boolean
}

const FixAllFamiliesDrawer: FunctionComponent = () => {
  const drawerType = 'fix-all-families'
  const drawer = useSelector(assetsSlice.selectDrawer)
  const [errorText, setErrorText] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [fixedFamilies, setFixedFamilies] = useState<FixedFamiliesResponse[]>([])
  const [partialFixedFamilies, setPartialFixedFamilies] = useState<FixedFamiliesResponse[]>([])
  const [startedAt, setStartedAt] = useState<Date>(new Date())
  const [finishedAt, setFinishedAt] = useState<Date>(new Date())
  const [progress, setProgress] = useState(0)
  const [status, setStatus] = useState(FixingFamiliesStatusEnum.NONE)

  const dispatch = useDispatch()

  const handleCloseDrawer = () => {
    dispatch(assetsSlice.actions.toggleDrawer({ visible: false, type: drawerType, data: null }))
  }

  const [, fixAllFamiliesSVGs] = useAxios(
    {
      url: `/v4/image-engine/fix-families`,
      method: 'PATCH',
    },
    { manual: true, autoCancel: false }
  )

  const [, getfixFamiliesResults] = useAxios(
    {
      url: `/v4/image-engine/fix-families-results`,
      method: 'GET',
    },
    { manual: true, autoCancel: false }
  )

  const handleFix = async ({
    onlyPartialFamilies,
    startFromLastFamily,
  }: {
    onlyPartialFamilies?: boolean
    startFromLastFamily: boolean
  }) => {
    try {
      setLoading(true)
      setErrorText(null)

      await fixAllFamiliesSVGs(
        onlyPartialFamilies
          ? { data: { selectedFamilies: partialFixedFamilies.map((family) => family.familySlug) } }
          : { params: { startFromLastFamily } }
      )

      await getResults()

      message.success(
        `Fixing family icons has started successfully. This process can take a while, so we'll do everything in the background and once it's done we'll let you know in Slack.`,
        10
      )
    } catch (error) {
      setLoading(false)

      // @TODO For some reason this functionality throws a cancel error when clicked
      // the second time. Cause is unknown, it should be fixed.
      if (axios.isCancel(error)) {
        message.warning('Please click again')
      } else {
        const responseError = handleError(error)
        setErrorText(responseError)
      }
    }
  }

  const getResults = useCallback(async () => {
    try {
      const { data } = await getfixFamiliesResults()

      setFixedFamilies(
        data.response.filter((response: FixedFamiliesResponse) => response.allIconsFixed)
      )

      setPartialFixedFamilies(
        data.response.filter((response: FixedFamiliesResponse) => !response.allIconsFixed)
      )

      setStartedAt(data.startedAt)
      setFinishedAt(data.finishedAt)
      if (data.totalProcessedFamilies > 0) {
        setProgress((data.totalProcessedFamilies / data.totalFamilies) * 100)
      }
      setLoading(data.status === FixingFamiliesStatusEnum.IN_PROGRESS)
      setStatus(data.status)
    } catch (error) {
      message.error('Error getting the results.')
    }
  }, [getfixFamiliesResults])

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined
    getResults()

    if (loading) {
      intervalId = setInterval(() => {
        getResults()
      }, 1000 * 10) // 10 seconds
    }
    return () => {
      if (intervalId) return clearInterval(intervalId)
      return undefined
    }
  }, [getResults, loading])

  return (
    <>
      <Drawer
        destroyOnClose
        width={720}
        placement="right"
        closable={false}
        maskClosable={false}
        onClose={() => handleCloseDrawer()}
        visible={drawer.type === drawerType && drawer.visible}
      >
        <DrawerHeader
          showSaveButton={false}
          isSaving={loading}
          title="Automatically fix all images SVG of all families"
          onClickCancel={() => handleCloseDrawer()}
          showDeleteButton={false}
        />

        <Spin spinning={loading} tip={`Fixing family icons - ${progress.toFixed(1)}%`}>
          <>
            {fixedFamilies.length > 0 && (
              <>
                <h4>
                  Results from the last fixing process, started at:{' '}
                  {format(new Date(startedAt), 'dd/MM/yy : HH:mm')} and finished at:{' '}
                  {format(new Date(finishedAt), 'dd/MM/yy : HH:mm')}.
                </h4>

                {status === FixingFamiliesStatusEnum.TIMEOUT && (
                  <h3 style={{ color: '#cf1322' }}>
                    The process timed out, some families may not have been fixed.
                  </h3>
                )}

                <p style={{ color: '#3f8600' }}>Successfull fixed families</p>

                <List
                  itemLayout="vertical"
                  size="large"
                  pagination={{
                    pageSize: 10,
                    simple: true,
                  }}
                  dataSource={fixedFamilies || []}
                  renderItem={(family) => (
                    <List.Item key={family.familySlug}>
                      <div>{family.familySlug}</div>
                    </List.Item>
                  )}
                />
              </>
            )}
            {partialFixedFamilies.length > 0 && (
              <>
                <p style={{ color: '#cf1322' }}>Partial fixed families</p>

                <List
                  itemLayout="vertical"
                  size="large"
                  pagination={{
                    pageSize: 10,
                    simple: true,
                  }}
                  dataSource={partialFixedFamilies || []}
                  renderItem={(family) => (
                    <List.Item key={family.familySlug}>
                      <Collapse>
                        <Collapse.Panel
                          key={family.familySlug}
                          header={`${family.familySlug} ${
                            family.fixingTimeout ? '- the fixing process timed out' : ''
                          }`}
                        >
                          <p>
                            {`Total results: ${
                              family.responseErrors.length
                            } - ${family.errorPercentage?.toFixed(2)}% of error`}
                          </p>
                          <List
                            itemLayout="vertical"
                            size="large"
                            pagination={{
                              pageSize: 10,
                              simple: true,
                            }}
                            dataSource={family.responseErrors || []}
                            renderItem={(icon) => (
                              <List.Item
                                key={icon.fixedSVG}
                                className={
                                  icon.status === FixResultStatusEnum.ERROR ? styles.Error : ''
                                }
                              >
                                <div>
                                  <SVGComparison
                                    firstSVG={icon.previousSVG}
                                    firstTitle="Image's current SVG"
                                    secondSVG={icon.fixedSVG}
                                    secondTitle="Image to save after fixing"
                                  />
                                  {icon.status === FixResultStatusEnum.NOT_MODIFIED && (
                                    <Text type="secondary">
                                      Image's SVG after fixing will stay the same, so it is already
                                      fixed.
                                    </Text>
                                  )}
                                  {icon.message && <>{icon.message}</>}
                                  <br />
                                  <a
                                    href={`https://streamlinehq.com/${icon.path}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {icon.path}
                                  </a>
                                </div>
                              </List.Item>
                            )}
                          />
                        </Collapse.Panel>
                      </Collapse>
                    </List.Item>
                  )}
                />
              </>
            )}

            <h4>Fix SVG automatically</h4>

            {fixedFamilies.length === 0 && <ChangesListFix strokeAllowed />}

            <p>
              Be aware that there will be no preview, all assets will be fixed and saved directly in
              the database.
            </p>

            {status === FixingFamiliesStatusEnum.STOPPED && (
              <Row style={{ marginBottom: '10px' }}>
                <p style={{ color: 'red' }}>
                  The last fixing families process was paused. So far {progress.toFixed(1)}% of the
                  families were fixed. Click the button bellow to resume the process.
                </p>
                <Tooltip title="Fix remaining families and save the changes to the database by clicking here.">
                  <Button
                    htmlType="button"
                    size="large"
                    onClick={() => handleFix({ startFromLastFamily: true })}
                  >
                    Resume paused process
                  </Button>
                </Tooltip>
              </Row>
            )}

            <Tooltip title="Fix all families and save the changes to the database by clicking here.">
              <Button
                htmlType="button"
                size="large"
                onClick={() => handleFix({ startFromLastFamily: false })}
              >
                Fix all families SVGs
              </Button>
            </Tooltip>

            <Tooltip title="Tries to fix only families that have been partially fixed.">
              <Button
                htmlType="button"
                size="large"
                onClick={() => handleFix({ startFromLastFamily: false, onlyPartialFamilies: true })}
                className={styles.partialFixButton}
                disabled={partialFixedFamilies.length === 0}
              >
                Retry only partial fixed families
              </Button>
            </Tooltip>
          </>
        </Spin>
        {errorText && <Text type="danger">{errorText}</Text>}
      </Drawer>
    </>
  )
}

export default FixAllFamiliesDrawer
