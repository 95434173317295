import { Form, Input, InputNumber } from 'antd'
import React, { FunctionComponent } from 'react'

const FamilyGroupFormLeftColumn: FunctionComponent = () => (
  <>
    <Form.Item
      label="Family Group Name"
      name="name"
      rules={[
        {
          required: true,
          message: 'Please input a name!',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Family Group Description"
      name="description"
      rules={[
        {
          required: false,
          max: 350,
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Family Group Subtitle"
      name="subtitle"
      rules={[
        {
          required: false,
          max: 350,
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Family Group Site URL"
      name="siteUrl"
      rules={[
        {
          required: false,
          max: 255,
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Family Group Slug"
      name="slug"
      rules={[
        {
          required: true,
          message: 'Please input a slug!',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Family Group Color"
      name="color"
      rules={[
        {
          required: true,
          message: 'Please input a color!',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Sorting number"
      name="order"
      rules={[
        {
          required: true,
          message: 'Please input a subcategory order!',
        },
      ]}
    >
      <InputNumber min={1} max={999} />
    </Form.Item>
  </>
)

export default FamilyGroupFormLeftColumn
