import { Tag, Tooltip, Button, message } from 'antd'
import axios from 'axios'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useAxios from '../../../../../app/api/apiHook'
import handleError from '../../../../../app/api/handleError'
import { FeedbackMessageType } from '../../../../../app/types'
import AntFormItemExplain from '../../../../../components/AntFormItemExplain/AntFormItemExplain'
import { assetsSlice, Icon, selectFamily } from '../../../assetsSlice'
import styles from './TagsSection.module.scss'

interface AutoTagsSectionProps {
  icon: Icon
  autoTags: string[]
}

const AutoTagsSection: FunctionComponent<AutoTagsSectionProps> = ({
  icon,
  autoTags: initialAutoTags,
}) => {
  const [generatingAutoTags, setGeneratingAutoTags] = useState(false)
  const [autoTags, setAutoTags] = useState(initialAutoTags || [])
  const family = useSelector(selectFamily)
  const [feedbackStatus, setFeedbackStatus] = useState<{
    type: FeedbackMessageType
    message: string
  }>({
    type: 'text',
    message: '',
  })

  const dispatch = useDispatch()

  const [, generateAutoTagsRequest] = useAxios(
    {
      url: `/v4/ai/auto-tagging/icons/${icon.hash}`,
      method: 'POST',
      data: { productType: family?.productType },
    },
    { manual: true }
  )

  const [, patchAutoTags] = useAxios(
    {
      url: `/v4/icons/${icon.hash}/auto-tags`,
      method: 'PATCH',
    },
    { manual: true }
  )

  const [, patchTags] = useAxios(
    {
      url: `/v4/icons/${icon.hash}/tags`,
      method: 'PATCH',
    },
    { manual: true }
  )

  const removeAutoTag = async (removedAutoTag: string) => {
    try {
      setFeedbackStatus({ type: 'text', message: 'Removing...' })

      const remainingAutoTags = autoTags.filter((autoTag) => autoTag !== removedAutoTag)

      await patchAutoTags({ data: { tags: remainingAutoTags } })

      setAutoTags(remainingAutoTags)
      dispatch(assetsSlice.actions.updateIconOnList({ ...icon, autoTags: remainingAutoTags }))

      setFeedbackStatus({ type: 'success', message: 'Auto tag removed!' })
    } catch (error: any) {
      if (!axios.isCancel(error)) {
        console.error(error.message)
        setFeedbackStatus({ type: 'error', message: 'Something went wrong, please try again' })
      }
    }
  }

  const generateAutoTags = async () => {
    setGeneratingAutoTags(true)

    try {
      const response = await generateAutoTagsRequest()
      const updatedAutoTags = response.data.autoTags

      setAutoTags(updatedAutoTags)

      if (updatedAutoTags?.length === 0) {
        setFeedbackStatus({
          type: 'error',
          message:
            'Tags generated by AI have a low accuracy score, so none were automatically added.',
        })
      }
    } catch (error) {
      message.error(handleError(error))
    } finally {
      setGeneratingAutoTags(false)
    }
  }

  useEffect(() => {
    setAutoTags(icon.autoTags || [])
  }, [icon])

  if (!family) {
    return null
  }

  return (
    <div className={styles.rootAutoTags}>
      <span>
        <b>Auto tags: </b>
      </span>
      {autoTags &&
        autoTags.map((autoTag) => (
          <Tooltip title={autoTag.length > 20 ? autoTag : undefined} key={autoTag}>
            <Tag key={`${icon.hash}_${autoTag}`} closable onClose={() => removeAutoTag(autoTag)}>
              <span className={styles.longTags}>{autoTag}</span>
            </Tag>
          </Tooltip>
        ))}

      <Button size="small" onClick={generateAutoTags} loading={generatingAutoTags}>
        Generate tags with AI
      </Button>

      <AntFormItemExplain message={feedbackStatus.message} type={feedbackStatus.type} />
    </div>
  )
}

export default AutoTagsSection
